var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isVisibleDialog),callback:function ($$v) {_vm.isVisibleDialog=$$v},expression:"isVisibleDialog"}},[_c('v-card',{staticClass:"mt-0",attrs:{"elevation":"4"}},[(!_vm.readOnly)?_c('v-tabs',{on:{"change":function($event){return _vm.tabChanged(_vm.tab)}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.tab},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item.tab},[(
            (item.content == 'edit-vehicle' && _vm.$admin.can('vehicles.edit')) ||
              _vm.readOnly
          )?_c('edit-vehicle-tab',{attrs:{"read-only":_vm.readOnly,"edit-vehicle":_vm.vehicle,"toggle-edit":_vm.toggleEdit,"is-visible-dialog":_vm.isVisibleDialog}}):_vm._e(),(
            item.content == 'assign-customer' &&
              _vm.$admin.can('vehicles.assign-vehicle')
          )?_c('assign-customer-tab',{attrs:{"is-fleet":_vm.isFleet,"read-only":_vm.readOnly,"edit-vehicle":_vm.vehicle,"toggle-edit":_vm.toggleEdit,"is-visible-dialog":_vm.isVisibleDialog}}):_vm._e()],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }