<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-card
      elevation="4"
      class="mt-0"
    >
      <v-tabs
        v-if="!readOnly"
        v-model="tab"
        @change="tabChanged(tab)"
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
        >
          <edit-vehicle-tab
            v-if="
              (item.content == 'edit-vehicle' && $admin.can('vehicles.edit')) ||
                readOnly
            "
            :read-only="readOnly"
            :edit-vehicle="vehicle"
            :toggle-edit="toggleEdit"
            :is-visible-dialog="isVisibleDialog"
          />

          <assign-customer-tab
            v-if="
              item.content == 'assign-customer' &&
                $admin.can('vehicles.assign-vehicle')
            "
            :is-fleet="isFleet"
            :read-only="readOnly"
            :edit-vehicle="vehicle"
            :toggle-edit="toggleEdit"
            :is-visible-dialog="isVisibleDialog"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {
    EditVehicleTab: () => import("./tabs/EditVehicleTab.vue"),
    AssignCustomerTab: () => import("./tabs/AssignCustomerTab.vue"),
  },
  mixins: [validationRules],

  props: {
    isVisibleDialog: Boolean,
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    // isFleet: {
    //   type: Boolean,
    //   default() {
    //     return false;
    //   },
    // },
    vehicle: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      tab: 0,
      // items: [
      //   {
      //     tab: this.readOnly ? this.$t("view") : this.$t("edit"),
      //     content: "edit-vehicle",
      //   },
      //  { tab: this.$t("assign-customers"), content: "assign-customer" }
      //   ,
      // ],
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      iotDevicesList: "iotDevices/getIotDevicesList",
      isLoadingIotDevices: "iotDevices/getIsLoadingIotDevices",
      mdMakesList: "mdMakes/activelistMdMakes",
      isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
      mdModelsList: "mdModels/activelistMdModels",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
    }),
    isFleet() {
      const id = this.vehicle.oem_b2b_id;
      return this.authUser.user_nature == "fleet" || id ? true : false;
    },
    items() {
      return [
        ...(this.$admin.can("vehicles.edit") || this.readOnly
          ? [
              {
                tab: this.readOnly ? this.$t("view") : this.$t("edit"),
                content: "edit-vehicle",
              },
            ]
          : []),
        ...(this.vehicle.is_assign_vehicle &&
        this.$admin.can("vehicles.assign-vehicle")
          ? [
              {
                tab: this.isFleet
                  ? this.$t("assign-drivers")
                  : this.$t("assign-customers"),
                content: "assign-customer",
              },
            ]
          : []),
      ];
    },

    isMdModelHasFixedBatterySlot() {
      var mdModel = this.mdModelsList.find(
        (item) => item.id === this.vehicle.md_model_id
      );

      if (mdModel) {
        return mdModel.battery_slot_type == "fixed";
      }

      return false;
    },
  },

  watch: {
    isVisibleDialog: {
      handler: async function (value) {
        if (!value) {
          this.tab = 0;
        }
      },
    },
  },

  methods: {
    async tabChanged(index) {
      try {
        if (this.items[index].content == "assign-customer") {
          let payload = {
            tenant_id: this.authUser.isAdmin ? this.vehicle.tenant_id : null,
          };
          let params = [
            this.vehicle.vehicle_customer,
            ...this.vehicle.test_customers,
          ];
          payload.vehicle_to = params == 0 ? null : params;

          if (this.vehicle.oem_b2b_id) {
            payload.oem_b2b_id = this.vehicle.oem_b2b_id ?? null;
          } else {
            payload.vehicle_assigned = params.length > 0 ? false : null;
          }

          this.$store.dispatch("oemCustomers/list", payload);
        }
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response.data.message);
      }
    },
  },
};
</script>
